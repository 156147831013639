import { mapState } from "vuex";
import { ctsApi } from "@/views/channel/api";
import shopHistory from "@/views/product/components/ShopHistory";
import Step from '@/components/global/Step/Step.vue'
// import NumberBox from '@/components/global/NumberBox/NumberBox.vue'
import { getPrices, getStocks } from "@/utils/api.js"; //获取产品库存接口//获取产品价格接口
import { showCartList, cartParam, checkChangGuiQty, delList, updateQuantity, getCust, clearCart } from "./api"
import { getChannelStockLIST, productSearch } from "./../product/api.js";
import {
  getChannelPrice, //获取DMS产品价格接口
  getChannelStock, //获取DMS产品库存接口
  queryCustBrowseRecord//商家浏览记录列表接口
} from "@/utils/api.js";
export default {
  name: "index",
  components: {
    shopHistory,
    Step,
    // NumberBox
  },
  data() {
    return {
      gongyingshang:0,
      historyList: [],//浏览记录list
      steps: ['选择商品', '确认订单信息', '提交订单'],
      step: 1,//进度条
      pageLoadFlag: false,
      // currentProvide: undefined,
      dataList: [],
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/shoppingCart",
          name: "shoppingCart",
          title: "购物车"
        }
      ],
      windowList: [],
      opened: false,
      cartDataArray: [], // 个人购物车数据重构集合
      orgAndGroupArray: [],  // 供应商-物料组组合数据数组
      orgAndGroupKeys: [], // 供应商-物料组组合code集合
      filterFrom: {
        orgId: "",
        productId: "",
        purchaseType: ''
      },
      orgId: '',
      productId: '',
      chooseNum: 1,
      isCollapse: false, //是否折叠  
      chooseProductNum: '',
      chooseProductVolume: '',
      chooseProductPrice: '',
      pricesRes: [],
      allTotal: 0,
      allnum: 0,
      volumeTotal: 0,
      visible: false,
      allcheckBox: false,
      checkGroId: '',
      pliceName: '',
      showBottom: false,
      shopvisible: false,
      visibleshop: false,
      visibleclearCart: false,
      shopnumMessage: false,
      shopnum: false,
      onluyOneMessage: false,
      onldelectShop: false,
      isFixed: false,
      mesageInfoTips: null,
      regularOrderTips1: false,
      regularOrderTips: false,
      emergencyOrderTips: false,
      ChannelOrderTip: false,
      showBottomButton1: true,
      showBottomButton2: true,
      showBottomButton3: true,
      returnGoodsList: [],
      showImg: false,
      isShowQD: false,
      isShowCG: false,
      isShowYj: false,
      marketModels: null,
      cartInfoTip:''
    };
  },
  computed: {
    ...mapState({
      //处理后的筛选数据list
      filList: (state) => state.filtrate.filList,
      //筛选组件加载loadding
      filterLoadFlag: (state) => state.filtrate.isLoading,
      //用于查询列表的筛选配置字段
      filterConfigStr: (state) => state.filtrate.filterConfigStr,
      isModelShow: (state) => state.user.isModelShow,
    }),
    userInfo() {
      return this.$store.state.user.userInfo
        ? this.$store.state.user.userInfo
        : localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo"))
        : "";
    }
  },
  mounted() {
    this.marketModels = this.$store.state.user.userInfo.account.marketModels
    //筛选后续操作的入参 加后台已定义的 formCode 便于筛选后查询
    this.filterFrom = { ...this.filterFrom, formCode: this.filterConfigStr ? this.filterConfigStr : '' };
    if (!this.filterFrom.purchaseType) {
      this.filterFrom.purchaseType = ''
    }
    // this.getProductSearch()
    // this.initCartDataPage()
    window.addEventListener("scroll", this.throttle(this.handleScroll, 100), true);
    this.queryCustBrowseRecord();
    this.getMessage()
  },
  methods: {
    getMessage() {
      const msg =  this.$configTotal({
        route: this.$route.fullPath,
        id:'14187495683'
      })
      this.cartInfoTip = msg.msgDesc
    },
    //浏览足迹
    async queryCustBrowseRecord() {
      this.pageLoadFlag = true;
      try {
        const res = await queryCustBrowseRecord({ num: '12' });
        if (res) {
          this.historyList = res.data.data;
          if (res && res.data.data && res.data.data.length > 0) {
            for (const idx in res.data.data) {
              if (res.data.data[idx].isFenXiao == "N") {
                let param = {
                  code: res.data.data[idx].productId,
                  orgId: res.data.data[idx].orgId,
                  orgCode: res.data.data[idx].orgCode,
                };
                let paramDmsStock = {
                  productCodes: [res.data.data[idx].productId],
                  supplierCode: '',
                };
                this.getPrices(idx, param, 'history');
                this.getStocks(idx, param, 'history');
                this.getChannelStock(idx, paramDmsStock, 'history');
              } else if (res.data.data[idx].isFenXiao == "Y") {
                let paramDmsPrice = {
                  orgId: res.data.data[idx].orgId,
                  productId: res.data.data[idx].productId,
                };
                let paramDmsStock = {
                  productCodes: [res.data.data[idx].productId],
                  supplierCode: res.data.data[idx].agentCisCode,
                };
                this.getChannelPrice(idx, paramDmsPrice, 'history');
                this.getChannelStock(idx, paramDmsStock, 'history');
              }
            }
          }
          this.pageLoadFlag = false;
        }
      }
      catch (error) {
        console.log(error);
        this.pageLoadFlag = false;
      }
    },
    //获取价格
    getPrices(idx, param, code) {
      getPrices(param)
        .then((pricesRes) => {
          // 源数据赋值库存
          // if (pricesRes.statusText == "OK") {
          // 标准价格
          this.$set(
            code == 'history' ? this.historyList[idx] : this.dataList[idx],
            "standardPrice",
            pricesRes.data[0].standardPrice
              ? pricesRes.data[0].standardPrice.toFixed(2)
              : null
          );
          //折扣价格
          this.$set(
            code == 'history' ? this.historyList[idx] : this.dataList[idx],
            "retailPrice",
            pricesRes.data[0].retailPrice
              ? Number(pricesRes.data[0].retailPrice)
                .toFixed(2)
                .toString()
              : null
          );
          this.$forceUpdate();
          // }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getChannelPrice(idx, param, code) {
      getChannelPrice(param)
        .then((pricesRes) => {
          // 标准价格
          this.$set(
            code == 'history' ? this.historyList[idx] : this.dataList[idx],
            "standardPrice",
            pricesRes.data.list[0].standardPrice
              ? pricesRes.data.list[0].standardPrice.toFixed(2)
              : null
          );
          //折扣价格
          this.$set(
            code == 'history' ? this.historyList[idx] : this.dataList[idx],
            "retailPrice",
            pricesRes.data.list[0].retailPrice
              ? Number(pricesRes.data.list[0].retailPrice).toFixed(2)
              : null
          );
          this.$forceUpdate();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //获取库存
    getStocks(idx, param, code) {
      // 根据产品列表获取库存
      getStocks(param)
        .then((stocksRes) => {
          // 源数据赋值库存
          this.$set(
            code == 'history' ? this.historyList[idx] : this.dataList[idx],
            "inventory",
            stocksRes.data[0].inventory
          );
          this.$set(
            code == 'history' ? this.historyList[idx] : this.dataList[idx],
            "sharedInv",
            stocksRes.data[0].sharedInv
          );
          this.$forceUpdate();
          // }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //获取库存
    getChannelStock(idx, param, code) {
      // 根据产品列表获取库存
      getChannelStock(param)
        .then((stocksRes) => {
          let res = JSON.parse(stocksRes.data);
          // if(stocksRes.status == 200 && res.code == 0){
          // 库存
          this.$set(code == 'history' ? this.historyList[idx] : this.dataList[idx], "invQty", res.data[0].invQty);

          this.$set(code == 'history' ? this.historyList[idx] : this.dataList[idx], "gicInvQty", res.data[0].gicInvQty);
          // }

          this.$forceUpdate();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 商品详情
    gotoDetail(id, orgId) {
      this.$router.push({
        path: '/product/detail',
        query: {
          productCode: id,
          orgId: orgId
        }
      })
    },
    // 显示删除弹出框
    showDelectVisible() {
      let arr = []
      this.cartDataArray.forEach(item => {
        item.cartDTOArray.forEach(goodItem => {
          if (goodItem.checkSelectFlag == true) {
            arr.push(
              {
                carts: goodItem.id,
              }
            )
          }
        })
      })
      if (arr.length <= 0) {
        this.shopnum = true
        return
      } else {
        this.visibleshop = true
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: '14180000001'
        })
        this.mesageInfoTips = msg.msgDesc
      }
    },
    //  底部删除确认
    handleshopOk() {
      let arr = []
      this.cartDataArray.forEach(item => {
        item.cartDTOArray.forEach(goodItem => {
          if (goodItem.checkSelectFlag == true) {
            arr.push(
              {
                carts: goodItem.id,
              }
            )
          }
        })
      })

      if (arr.length <= 0) {
        this.$message.warning("请至少选择一个商品！");
        return
      }
      var str = "" //商品id
      for (let i = 0; i < arr.length; i++) {
        str += arr[i].carts + ",";

      }
      delList({ cartIds: str }).then(() => {
        // 删除以后重新调用初始化数据
        //TODO:周一给天翔
        // const msg =  this.$configTotal({
        //   route: this.$route.fullPath,
        //   id:'14180000001'
        // })
        // this.mesageInfoTips = msg.msgDesc
        this.$message.success("删除商品成功");

        this.$set(
          // item, "opened", !item.opened
          this, "allTotal", 0,
          this, "allnum", 0,
          this, "volumeTotal", 0
        )
        this.initCartDataPage()
        //获取购物车数量
        this.$store.dispatch("user/getCartNum")
      }).catch(error => {
        console.log(error)
      })
      this.shopvisible = false
    },
    //代码：
    throttle(func, delay) {
      var prev = Date.now();
      return function () {
        var context = this;
        var args = arguments;
        var now = Date.now();
        if (now - prev >= delay) {
          func.apply(context, args);
          prev = Date.now();
        }
      }
    },

    isElementInViewport(el) {
      //获取元素是否在可视区域
      const rect = el.getBoundingClientRect();
      return (
        rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
        (window.innerWidth || document.documentElement.clientWidth)
      );
    },

    // 滚动
    handleScroll() {
      if (this.$refs.footerRef) {
        this.isFixed = !this.isElementInViewport(this.$refs.footerRef);
      }
    },
    // 展开更多政策
    more(id) {
      this.cartDataArray.forEach(item => {
        item.cartDTOArray.forEach(goodItem => {
          if (goodItem.id == id) {
            goodItem.openPoliciesFlag = !goodItem.openPoliciesFlag
          }
        })
      })
    },
    // 移除所选政策
    delectPlice(index, code, id, cartDataProductId, cartDataOrgId, cartDataOrgCode, cartDataIndex, quantity) {
      this.cartDataArray.forEach(item => {
        item.cartDTOArray.forEach(goodItem => {
          goodItem.policies.forEach(pliceItem => {
            if (goodItem.id == id) {
              goodItem.canQuantity = 100000000000000000000000
              goodItem.policyId1 = null
              goodItem.policyId = null
              pliceItem.ischerk = false
              goodItem.pliceNameFull = ''
              goodItem.openPoliciesFlag = false
              // goodItem.pricestand =( goodItem.oldPrice * (100 - goodItem.discut) /100).toFixed(2)
              if (goodItem.policyId1 == null) {
                goodItem.policyId1 = ''
              }
              updateQuantity({ cartId: id, policyId: goodItem.policyId1, quantity: quantity }).then(res => {
                if (res.data == 'Y') {

                } else {

                }
              })
            }
          })
        })
      })
      getPrices({ code: cartDataProductId, orgId: cartDataOrgId, orgCode: cartDataOrgCode }).then(res => {
        this.cartDataArray[index].cartDTOArray[cartDataIndex].pricestand = (res.data[0].retailPrice).toFixed(2)
        this.cartDataArray[index].cartDTOArray[cartDataIndex].discut = res.data[0].fixedDiscount,
          this.cartDataArray[index].cartDTOArray[cartDataIndex].CompensationType = res.data[0].makeUpType,
          this.cartDataArray[index].cartDTOArray[cartDataIndex].oldPrice = (res.data[0].price).toFixed(2),
          this.cartDataArray[index].cartDTOArray[cartDataIndex].PricingGroup = res.data[0].pricingGroupName,
          this.cartDataArray[index].cartDTOArray[cartDataIndex].PricingGroupCode = res.data[0].pricingGroupCode
        this.totalCount(index, code)
      }).catch(error => {
        console.log(error)
      })
    },
    // 底部全选
    allcherkall(e) {
      this.cartDataArray.forEach((item, index) => {
        if (item.canCherk == false) {
          item.isSelectAll = e.target.checked
        } else {
          item.isSelectAll == item.isSelectAll
        }
        item.cartDTOArray.forEach(goodItem => {
          if (goodItem.pricestand > 0 && goodItem.isSell == 'Y') {
            goodItem.checkSelectFlag = e.target.checked
          }
        })
        this.totalCount(index, item.code)
      })
      // this.getCherkProduct()
    },
    // 全选校验
    allSelectCheck() {
      let flag = true
      for (let i = 0; i < this.cartDataArray.length; i++) {
        if (!this.cartDataArray[i].isSelectAll) {
          flag = false
          break
        }
      }
      this.allcheckBox = flag
    },
    // 点击政策触发
    clickBox(id, cartDataArrayIndex, cartDataIndex, policyIndex, cartId, orgcode, orgId, quantity, policyInfo) {
      policyInfo.ischerk = !policyInfo.ischerk
      if (policyInfo.ischerk == true) {
        let pliceItemId = null
        this.cartDataArray.forEach(item => {
          item.cartDTOArray.forEach(goodItem => {
            goodItem.policies.forEach((pliceItem, index) => {
              if (goodItem.id == this.cartDataArray[cartDataArrayIndex].cartDTOArray[cartDataIndex].id) {
                if (pliceItem.ischerk) {
                  pliceItemId = index
                }
                if (this.cartDataArray[cartDataArrayIndex].cartDTOArray[cartDataIndex].id == goodItem.id) {
                  pliceItem.ischerk = false
                }
              }
              if (pliceItem.id === id) {
                if (pliceItem.canQuantity >= goodItem.quantity) {
                  goodItem.policyId1 = id
                  pliceItem.ischerk = true
                  if (pliceItem.versionCode) {
                    goodItem.pliceNameFull = pliceItem.policyName + '-' + pliceItem.versionCode + "\xa0\xa0" + '开票价:' + pliceItem.price + '元' + "\xa0\xa0" + '可购数量:' + pliceItem.canQuantity
                  } else {
                    goodItem.pliceNameFull = pliceItem.policyName + "\xa0\xa0" + '开票价:' + pliceItem.price+ '元' + "\xa0\xa0" + '可购数量:' + pliceItem.canQuantity
                  }
                  goodItem.openPoliciesFlag = false
                  goodItem.canQuantity = pliceItem.canQuantity
                  let params = {
                    versionId: id,
                    orgCode: orgcode,
                    orgId: orgId,
                    productId: this.cartDataArray[cartDataArrayIndex].cartDTOArray[cartDataIndex].productId
                  }
                  getCust(params).then(res => {
                    this.cartDataArray[cartDataArrayIndex].cartDTOArray[cartDataIndex].PricingGroup = res.data.pricingGroupName
                    this.cartDataArray[cartDataArrayIndex].cartDTOArray[cartDataIndex].PricingGroupCode = res.data.pricingGroupCode
                    this.cartDataArray[cartDataArrayIndex].cartDTOArray[cartDataIndex].CompensationType = res.data.makeUpType
                    this.cartDataArray[cartDataArrayIndex].cartDTOArray[cartDataIndex].discut = res.data.fixedDiscount
                    this.cartDataArray[cartDataArrayIndex].cartDTOArray[cartDataIndex].pricestand =
                      ((this.cartDataArray[cartDataArrayIndex].cartDTOArray[cartDataIndex].policies[policyIndex].price * (100 - (res.data.fixedDiscount))) / 100).toFixed(2)
                    this.totalCount(cartDataArrayIndex, item.code)
                  }).catch(error => {
                    console.log(error)
                  })
                  updateQuantity({ cartId: cartId, policyId: goodItem.policyId1, quantity: quantity }).then(res => {
                    if (res.data == 'Y') {
                      console.log('y');
                    }
                  }).catch(error => {
                    console.log(error)
                  })
                } else {
                  goodItem.policies[pliceItemId].ischerk = true
                }
              }
            })
          })
        })
      } else {
        this.delectPlice(
          cartDataArrayIndex,
          this.cartDataArray[cartDataArrayIndex].code,
          cartId,
          this.cartDataArray[cartDataArrayIndex].cartDTOArray[cartDataIndex].productId,
          orgId,
          orgcode,
          cartDataIndex,
          quantity
        )
      }

    },

    // 购物车内商品数量选择变化
    onCartDataInputNumChange(cartDataArrayIndex, code, id, cartDataIndex, policyId1, value) {
      this.cartDataArray[cartDataArrayIndex].cartDTOArray[cartDataIndex].quantity = Number(value)
      if (policyId1 == null) {
        policyId1 = ''
      }
      this.pageLoadFlag = true;
      updateQuantity({ cartId: id, policyId: policyId1, quantity: value }).then(res => {
        if (res.data == 'Y') {
          this.$store.dispatch("user/getCartNum")
          this.totalCount(cartDataArrayIndex, code)
        }
        this.pageLoadFlag = false;
      }).catch(error => {
        console.log(error)
        this.pageLoadFlag = false;
      })

      // this.$store.dispatch("user/getCartInfoList") 
    },

    // 显示体积弹窗
    showModal() {
      this.visible = true;
    },


    // 二次确认删除购物车
    confirm(id) {
      delList({ cartId: id }).then(() => {
        // 删除以后重新调用初始化数据
        this.initCartDataPage()
        this.$store.dispatch("user/getCartNum")
        this.allcheckBox = false
        this.allTotal = 0
        this.allnum = 0
        this.volumeTotal = 0
      }).catch(error => {
        console.log(error)
      })
    },

    // 底部下单按钮
    allPlaceOrder(type) {
      if(this.userInfo.account.loginSystem.indexOf('14168810880') == '-1' && this.userInfo.account.loginSystem.indexOf('14168810879') == '-1'){
        this.$message.info("此账号暂无下单权限");
        return
      }
      let arr = []
      this.cartDataArray.forEach(item => {
      this. gongyingshang = 0
        item.cartDTOArray.forEach(goodItem => {
          if (goodItem.checkSelectFlag == true) {
            arr.push(
              {
                supplierNameF:item.supplierName,
                supplierName: item.supplier,
                carts: goodItem.id,
                productId: goodItem.productId,
                num: goodItem.quantity,
                versions: goodItem.policyId1 ? goodItem.policyId1 : '',
                orgId: goodItem.orgId ? goodItem.orgId : '', //组织id、
                orgAndGroup: item.code,
                purchaseType: type,
                OrderType: goodItem.purchaseType,
                supplierType: goodItem.supplierType,
                model:goodItem.b2bName,
                color:goodItem.color
              }
            )
          }
        })
      })

      // 判断购物车下单商品是否大于一个，不是则弹出提示
      if (!arr || arr.length === 0) {
        this.shopnumMessage = true
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: '14180000003'
        })
        this.mesageInfoTips = msg.msgDesc
        return
      }
      // 获取当前下单供应商数量
      let hash = []
      for (let j = 0; j < arr.length; j++) {
        if (hash.indexOf(arr[j].orgAndGroup) == -1) {
          hash.push(arr[j].orgAndGroup);
        }
      }
      // 下单不能跨供应商-物料组下单 超过一个弹出提示
      //type==3 分销商渠道下单 如果供应商不一致则提示 要是供应商一直就不提示
      //例如 宇恒电器-冰箱青岛 、 宇恒电器-电视青岛 就不提示
      //例如 北京电视-冰箱青岛 、 宇恒电器-电视青岛 就弹出提示
      // type！==3 还是之前的逻辑
      for (let a = 0;a < arr.length;a++) {
        for (let b = 0;b < arr.length;b++) {
          if(arr[a].supplierNameF !== arr[b].supplierNameF ) {
            this.gongyingshang+= 1
          }
          break
        }
      }
      if(type == 3) {
        if(this.gongyingshang > 0) {
          this.onluyOneMessage = true
          // const msg = this.$configTotal({
          //   route: this.$route.fullPath,
          //   id: '14180000004'
          // })
          // this.mesageInfoTips = msg.msgDesc
          this.mesageInfoTips = '目前不允许跨供应商下单,请重新选择'
          return
        }
      }else  {
        if (hash.length > 1) {
          this.onluyOneMessage = true
          const msg = this.$configTotal({
            route: this.$route.fullPath,
            id: '14180000004'
          })
          this.mesageInfoTips = msg.msgDesc
          return
        }
      }


      var str = "" //商品id
      var vIds = '' //组织id重组
      var policyIdS = [] //物料组id
      var num = '' //num
      var productId = ''
      var orgId = ''
      var supplierName = ''
      for (let i = 0; i < arr.length; i++) {
        str += arr[i].carts + ",";
        vIds += arr[i].versions + ","
        orgId = arr[0].orgId,
        policyIdS.push(arr[i].orgAndGroup);
        productId += arr[i].productId + ",";
        num += arr[i].num + ",";
        supplierName = arr[0].supplierName
      }
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        str = str.substr(0, str.length - 1);
        vIds = vIds.substr(0, vIds.length - 1);
        productId = productId.substr(0, productId.length - 1);
        // policyIdS = policyIdS.substr(0, policyIdS.length - 1);
        num = num.substr(0, num.length - 1);
      }
      // 常规下单传参policyIdS只获取一个传到下一个页面
      if (type === 2) {
        // 应急下单型号数组
        let emergencyOrderNo = []
        for (let i = 0; i < arr.length; i++) {
          // supplierType == 3为渠道下单,arr[i].OrderType == '1'为应急下单
          if( arr[i].OrderType == '1' ) {
            emergencyOrderNo.push(arr[i].model+(arr[i].color|| ''))
          }
          if (arr[i].OrderType == '1' || arr[i].supplierType == 3) {
            emergencyOrderNo.push(arr[i].model+(arr[i].color|| ''))
            this.regularOrderTips = true
            let emergencyOrderNos = emergencyOrderNo.join(',')
            this.mesageInfoTips = '选择的'+ emergencyOrderNos+'产品不支持常规下单'
            return
          }
        }
        let Ruquestdata = {
          pids: productId,
          qtys: num,
          orgId: orgId ? orgId : ','
        }
        checkChangGuiQty(Ruquestdata).then(res => {
          if (res.data.code == 0) {

            this.$router.push({
              path: "/confirm",
              query: {
                carts: str,
                versions: vIds,
                orgAndGroup: policyIdS[0],
                purchaseType: type
              }
            });
          } else {
            this.mesageInfoTips = res.data.msg
            this.regularOrderTips1 = true
            return
          }
        }).catch(error => {
          console.log(error)
        })
      }
      // 应急下单 传参policyIdS只获取一个传到下一个页面
      if (type === 1) {
        this.mesageInfoTips = ''
        let nomalmodels = []
        for (let i = 0; i < arr.length; i++) {
          if(arr[i].OrderType == '2' ) {
           
            nomalmodels.push(arr[i].model+(arr[i].color|| ''))
          }
          if(arr[i].supplierType == 3){
            nomalmodels.push(arr[i].model)
          }
         
          if (arr[i].OrderType == '2' || arr[i].supplierType == 3) {
            this.emergencyOrderTips = true
            let nomalmodel = nomalmodels.join(',')
            this.mesageInfoTips = '选择的'+ nomalmodel+'产品不支持应急下单'
            break
          } else {

            this.$router.push({
              path: "/confirm",
              query: {
                carts: str,
                versions: vIds,
                orgAndGroup: policyIdS[0],
                purchaseType: type
              }
            });
          }
        }
      }
      // 渠道下单 传参policyIdS转换参数，隔开
      if (type == 3) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].supplierType == 2) {
            this.ChannelOrderTip = true
            const msg = this.$configTotal({
              route: this.$route.fullPath,
              id: '14180000007'
            })
            this.mesageInfoTips = msg.msgDesc
            break
          } else {
            this.$router.push({
              path: "/confirm/channel",
              query: {
                carts: str,
                versions: vIds,
                orgAndGroup: policyIdS.join(','),
                purchaseType: type,
                supplierName: supplierName
              }
            });
          }
        }
      }
    },
    // 立即下单
    immediatelyOrder() {
      if(this.userInfo.account.loginSystem.indexOf('14168810880') == '-1' && this.userInfo.account.loginSystem.indexOf('14168810879') == '-1'){
        this.$message.info("此账号暂无下单权限");
        return
      }
      let arr = []
      let cg = 0//常规
      let yj = 0//应急
      let yjcgNum = 0// 应急常规
      let qd = 0//渠道
      let other = 0//非渠道
      let modelNll = []
      this.cartDataArray.forEach(item => {
        this. gongyingshang = 0
        item.cartDTOArray.forEach(goodItem => {
          if (goodItem.checkSelectFlag == true) {
            if(goodItem.purchaseType == null || !goodItem.purchaseType) {
              modelNll.push(goodItem.b2bName)
            }
            // purchaseType== '1' 应急数量+1
            // purchaseType== '2'常规数量+1
            // purchaseType == 3 应急和常规
            if(goodItem.purchaseType== '2') {
              cg+= 1
            }
            if(goodItem.purchaseType== '1') {
              yj+= 1
            }
            if(goodItem.purchaseType == '3') {
              yjcgNum +=1
            }
            if(goodItem.isFenXiao== 'Y') {
              qd+= 1
            }
            if(goodItem.isFenXiao !== 'Y') {
              other+= 1
            }
            arr.push(
              {
                supplierNameF:item.supplierName,
                supplierName: item.supplier,
                cartsId: goodItem.id, // 购物车id
                productId: goodItem.productId, // 商品id
                num: goodItem.quantity, //数量
                versions: goodItem.policyId1 ? goodItem.policyId1 : '', //
                orgId: goodItem.orgId ? goodItem.orgId : '', //组织id
                orgAndGroup: item.code, //
                OrderType: goodItem.purchaseType,
                supplierType: goodItem.supplierType, // 下单类型
                model:goodItem.b2bName, // 型号名称
                color:goodItem.color,//颜色
              }
            )
          }
        })
      })
      // 判断购物车下单商品是否大于一个，不是则弹出提示
      if (!arr || arr.length === 0) {
        this.shopnumMessage = true
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: '14180000003'
        })
        this.mesageInfoTips = msg.msgDesc
        return
      }
      // 当前选择供应商个数
      for (let a = 0;a < arr.length;a++) {
        for (let b = 0;b < arr.length;b++) {
          if(arr[a].supplierNameF !== arr[b].supplierNameF ) {
            this.gongyingshang+= 1
          }
          break
        }
      }
      let str = [] //商品id
      let vIds = [] //组织id重组
      let policyIdS = [] //物料组id
      let num = [] //num
      let productId = []
      let orgId = []
      let supplierName = []
      for (let i = 0; i < arr.length; i++) {
        str.push( arr[i].cartsId)
        vIds.push(arr[i].versions)
        orgId.push(arr[0].orgId)
        policyIdS.push(arr[i].orgAndGroup);
        productId.push(arr[i].productId )
        num.push(arr[i].num)
        supplierName.push (arr[i].supplierName)
      }
      // 获取当前下单供应商数量
      let hash = []
      for (let j = 0; j < arr.length; j++) {
        if (hash.indexOf(arr[j].orgAndGroup) == -1) {
          hash.push(arr[j].orgAndGroup);
        }
      }
      if(other>0 && qd > 0 ) {
        this.onluyOneMessage = true
        this.mesageInfoTips = '目前不允许跨供应商下单,请重新选择'
        return
      }
      // 如果选择了海信单 没选择渠道单 就给出提示
      if(other>0 && qd <= 0){
        if (hash.length > 1) {
          this.onluyOneMessage = true
          const msg = this.$configTotal({
            route: this.$route.fullPath,
            id: '14180000004'
          })
          this.mesageInfoTips = msg.msgDesc
          return
        }
      }
      // 渠道下单跳转
      if(other <= 0 && qd > 0) {
        if(this.gongyingshang > 0) {
          this.onluyOneMessage = true
          this.mesageInfoTips = '目前不允许跨供应商下单,请重新选择'
          return
        }
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].supplierType == 2) {
            this.ChannelOrderTip = true
            const msg = this.$configTotal({
              route: this.$route.fullPath,
              id: '14180000007'
            })
            this.mesageInfoTips = msg.msgDesc
            break
          }  else {
            this.$router.push({
              path: "/confirm/channel",
              query: {
                carts: str.join(','),
                versions: vIds.join(','),
                orgAndGroup: policyIdS.join(','),
                purchaseType: 3,
                supplierName: supplierName,
                canChange:'Y'
              }
            });
          }
        }
        return;
      }
      // 常规下单  应急下单
      // 如果有采购类型为null的 就提示
      if(modelNll.length > 0 && qd <= 0 ) {
        modelNll = modelNll.join(',')
        this.mesageInfoTips = `此型号【${modelNll}】未维护线下采购类型，请联系分公司`
        this.regularOrderTips1 = true
        return
      }
      // 常规应急不能一起下单
      if(yjcgNum <= 0 && cg > 0 && yj > 0){
        this.mesageInfoTips = '目前不支持跨采购类型下单，请重新选择！'
        this.regularOrderTips1 = true
        return
      }
      // 应急常规大于0   常规应急小于0  默认3 到确认页默认常规
      if(yjcgNum > 0  && cg <= 0 && yj<= 0){
        this.$router.push({
          path: "/confirm",
          query: {
            carts: str.join(','),
            versions: vIds.join(','),
            orgAndGroup:  policyIdS[0],
            purchaseType: 3,
            canChange:'Y'
          }
        });
        return
      }

      // 应急常规大于0 并且应急大于0  常规小于0  默认应急
      if(yjcgNum > 0  && yj > 0 && cg<= 0){
        this.$router.push({
          path: "/confirm",
          query: {
            carts: str.join(','),
            versions: vIds.join(','),
            orgAndGroup:  policyIdS[0],
            purchaseType: 1,
            canChange:'N'
          }
        });
        return
      }
      // 应急常规大于0 并且常规大于0  应急小于0  默认常规
      if(yjcgNum > 0  && cg> 0 && yj <= 0){
        this.$router.push({
          path: "/confirm",
          query: {
            carts: str.join(','),
            versions: vIds.join(','),
            orgAndGroup:  policyIdS[0],
            purchaseType: 2,
            canChange:'N'
          }
        });
        return
      }
      if(yjcgNum <= 0  && cg> 0 && yj <= 0){
        this.$router.push({
          path: "/confirm",
          query: {
            carts: str.join(','),
            versions: vIds.join(','),
            orgAndGroup:  policyIdS[0],
            purchaseType: 2,
            canChange:'N'
          }
        });
        return
      }
      if(yjcgNum <= 0  && cg<= 0 && yj > 0){
        this.$router.push({
          path: "/confirm",
          query: {
            carts: str.join(','),
            versions: vIds.join(','),
            orgAndGroup:  policyIdS[0],
            purchaseType: 1,
            canChange:'N'
          }
        });
        return
      }
    },

    // 底部删除按钮
    delectCart() {
      var arr = []
      this.cartDataArray.forEach(item => {
        item.cartDTOArray.forEach(goodItem => {
          if (goodItem.checkSelectFlag == true) {
            arr.push(
              {
                carts: goodItem.id,
              }
            )
          }
        })
      })
      var str = "" //商品id
      for (let i = 0; i < arr.length; i++) {
        str += arr[i].carts + ",";
      }
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        str = str.substr(0, str.length - 1);

      }
      if (arr.length <= 0) {
        // this.$message.warning("请至少选择一个商品！");
        // onluyOneMessage shopnumMessage
        this.shopnumMessage = true
        return
      }
      else {
        delList({ cartIds: str }).then(res => {
          if (res.status == 200) {
            this.$message.warning("删除商品成功");
            this.initCartDataPage()
            this.$router.go(0)
            this.$store.dispatch("user/getCartNum")
            this.allTotal = 0
            this.allnum = 0
            this.volumeTotal = 0
          } else {
            this.$message.warning("删除商品失败");
          }
        })
      }

    },



    //全选
    onCheckAllChange(index, code) {
      if (this.cartDataArray[index].code !== code) {
        return
      }
      let flag = false
      if (this.cartDataArray[index].isSelectAll) {
        flag = true
      }
      if (this.cartDataArray[index].cartDTOArray && this.cartDataArray[index].cartDTOArray.length > 0) {
        this.cartDataArray[index].cartDTOArray.forEach(item => {
          if (item.isSell == 'Y' && item.pricestand >= 0) {
            item.checkSelectFlag = flag
          }
        })
      }
      this.totalCount(index, code)
      this.allSelectCheck()
      // this.getCherkProduct()
    },
    // 获取选中商品
    getCherkProduct() {
      let arr = []
      this.cartDataArray.forEach(item => {
        item.cartDTOArray.forEach(goodItem => {
          if (goodItem.checkSelectFlag == true) {
            arr.push(goodItem)
          }
        })
      })
      let purchaseTypeArr = []
      let supplierTypeArr = []
      let isFenXiaoArr = []
      //渠道商品数
      let quNum = 0
      // 非渠道商品数
      let other = 0
      // 常规商品数量
      let cg = 0
      // 应急商品数量
      let yj = 0
      for (let i = 0; i < arr.length; i++) {
        purchaseTypeArr.push(arr[i].purchaseType)
        supplierTypeArr.push(arr[i].supplierType)
        isFenXiaoArr.push(arr[i].isFenXiao)
        // 如果是分销 渠道商品数量+1
        if(arr[i].isFenXiao== 'Y') {
          quNum+=1
        }
        // 如果是非分销 other数量+1
        if(arr[i].isFenXiao== 'N') {
          other+=1
        }
        // 如果非分销 并且purchaseType == 2 常规数量+1
        if(arr[i].isFenXiao== 'N' && arr[i].purchaseType== '2') {
          cg+=1
        }
        // 如果非分销 并且purchaseType == 1 应急数量+1
        if(arr[i].isFenXiao== 'N' && arr[i].purchaseType== '1') {
          yj+=1
        }
        //如果非分销 并且purchaseType == 3 应急和常规数量各+1
        if(arr[i].isFenXiao== 'N' && arr[i].purchaseType== '3') {
          cg+=1
          yj+=1
        }
      }
      this.filterFrom = { ...this.filterFrom };
      // 如果渠道数量大于1 并且常规数量和应急数量小于0 只展示渠道
      if(quNum>0 && cg<=0 && yj<=0&& (!this.filterFrom.purchaseType || (this.filterFrom.purchaseType == "" || undefined || null == null))) {
        this.showBottomButton1 = false
        this.showBottomButton2 = false
        this.showBottomButton3 = true
      }
      // 如果渠道数量大于0 常规大于0 应急小于0 应急不展示
      if(quNum>0 && cg>0 && yj<=0 && (!this.filterFrom.purchaseType || (this.filterFrom.purchaseType == "" || undefined || null == null))) {
        this.showBottomButton1 = true
        this.showBottomButton2 = false
        this.showBottomButton3 = true
      }
      //如果渠道数量大于0 应急大于0 常规小于0 常规不展示
      if(quNum>0 && cg<=0 && yj>0 && (!this.filterFrom.purchaseType || (this.filterFrom.purchaseType == "" || undefined || null == null))) {
        this.showBottomButton1 = false
        this.showBottomButton2 = true
        this.showBottomButton3 = true
      }
      //如果渠道数量大于0 应急大于0 常规大于0 都展示
      if(quNum>0 && cg>0 && yj>0) {
        this.showBottomButton1 = true
        this.showBottomButton2 = true
        this.showBottomButton3 = true
      }
      // 如果渠道小于0 其他按钮大于0 并且常规大于0 应急小于0  展示常规
      if(quNum<=0 && other>0 && cg >0 && yj<=0 && (!this.filterFrom.purchaseType || (this.filterFrom.purchaseType == "" || undefined || null == null))) {
        this.showBottomButton1 = true
        this.showBottomButton2 = false
        this.showBottomButton3 = false
      }
      // 渠道小于0 其他大于0 并且常规小于0 应急大于0 展示应急按钮
      if(quNum<=0 && other>0 && cg <= 0 && yj>0 && (!this.filterFrom.purchaseType || (this.filterFrom.purchaseType == "" || undefined || null == null))) {
        this.showBottomButton1 = false
        this.showBottomButton2 = true
        this.showBottomButton3 = false
      }
      // 如果渠道小于0  其他大于0  常规应急都大于0  常规应急都展示
      if(quNum<=0 && other>0 && cg > 0 && yj>0&& (!this.filterFrom.purchaseType || (this.filterFrom.purchaseType == "" || undefined || null == null))) {
        this.showBottomButton1 = true
        this.showBottomButton2 = true
        this.showBottomButton3 = false
      }
      if(quNum<=0  && other <= 0 && cg<=0 && yj<=0 &&(!this.filterFrom.purchaseType || (this.filterFrom.purchaseType == "" || undefined || null == null))) {
        this.showBottomButton1 = true
        this.showBottomButton2 = true
        this.showBottomButton3 = true
      }
    },
    // 单个商品选中
    onCheckOneCartDataChange(cartDataArrayIndex, code, cartDTOArrayIndex, cartDataId) {

      let flag = true
      if (this.cartDataArray[cartDataArrayIndex].code === code
        && this.cartDataArray[cartDataArrayIndex].cartDTOArray
        && this.cartDataArray[cartDataArrayIndex].cartDTOArray.length > 0
        && this.cartDataArray[cartDataArrayIndex].cartDTOArray[cartDTOArrayIndex]
        && this.cartDataArray[cartDataArrayIndex].cartDTOArray[cartDTOArrayIndex].id === cartDataId) {
        for (let i = 0; i < this.cartDataArray[cartDataArrayIndex].cartDTOArray.length; i++) {
          // 添加判断 当前组织isSell=='Y'的时候才进入判断
          if(this.cartDataArray[cartDataArrayIndex].cartDTOArray[i].isSell=='Y') {
            if (!this.cartDataArray[cartDataArrayIndex].cartDTOArray[i].checkSelectFlag) {
              flag = false
              break
            }
          }
          
        }
      }
      this.cartDataArray[cartDataArrayIndex].isSelectAll = flag
      this.allSelectCheck()
      this.totalCount(cartDataArrayIndex, code)
      // this.getCherkProduct()
    },

    // 总量计数（体积、数量、金额）
    totalCount(index, code) {
      if (this.cartDataArray[index].code === code) {
        // 获取总体积
        this.cartDataArray[index].volumeTotal = 0
        // 获取总数量
        this.cartDataArray[index].commodityNum = 0
        // 获取总金额
        this.cartDataArray[index].amountTotal = 0
        if (this.cartDataArray[index].cartDTOArray && this.cartDataArray[index].cartDTOArray.length > 0) {
          this.cartDataArray[index].cartDTOArray.forEach(item => {
            if (item.checkSelectFlag) {
              // 获取总体积
              this.cartDataArray[index].volumeTotal = this.cartDataArray[index].volumeTotal + (item.loadVolume * item.quantity)
              // 获取总数量
              this.cartDataArray[index].commodityNum = this.cartDataArray[index].commodityNum + item.quantity
              // 获取总金额
              this.cartDataArray[index].amountTotal = this.cartDataArray[index].amountTotal + (item.quantity * item.pricestand)
            }
          })
        }
      }
      this.allTotal = 0
      this.allnum = 0
      this.volumeTotal = 0
      this.cartDataArray.forEach(item => {
        if (!isNaN(item.amountTotal)) {
          this.allTotal += item.amountTotal
        }
        if (!isNaN(item.commodityNum)) {
          this.allnum += item.commodityNum
        }
        if (!isNaN(item.volumeTotal)) {
          this.volumeTotal += item.volumeTotal
        }
      })
    },

    // 展开或收起优惠政策
    openPoliciesFlagChange(cartDataArrayIndex, cartDTOArrayIndex, cartDataId) {
      if (this.cartDataArray[cartDataArrayIndex].cartDTOArray
        && this.cartDataArray[cartDataArrayIndex].cartDTOArray.length > 0
        && this.cartDataArray[cartDataArrayIndex].cartDTOArray[cartDTOArrayIndex]
        && this.cartDataArray[cartDataArrayIndex].cartDTOArray[cartDTOArrayIndex].id === cartDataId) {
        if (this.cartDataArray[cartDataArrayIndex].cartDTOArray[cartDTOArrayIndex].openPoliciesFlag) {
          this.cartDataArray[cartDataArrayIndex].cartDTOArray[cartDTOArrayIndex].openPoliciesFlag = false
        } else {
          this.cartDataArray[cartDataArrayIndex].cartDTOArray[cartDTOArrayIndex].openPoliciesFlag = true
        }
      }
    },
    // 获取筛选条件id
    onFilterChange(checkedObj) {

      this.filterFrom = { ...this.filterFrom, ...checkedObj[0] };
      this.showBottomButton1 = false
      this.showBottomButton2 = false
      this.showBottomButton3 = false
      if (this.filterFrom.purchaseType == "") {
        this.showBottomButton1 = true
        this.showBottomButton2 = true
        this.showBottomButton3 = true
      }

      this.allcheckBox = false
      this.allTotal = 0
      this.allnum = 0
      this.volumeTotal = 0
      this.initCartDataPage()

    },
    // 清空购物车
    emptyshopCart() {
      let data = {
        orgIds: this.orgId == 'all' ? '' : this.orgId,
        matklIds: this.productId == 'all' ? '' : this.productId
      }
      this.pageLoadFlag = true;
      clearCart(data).then(res => {
        if (res.data.code == 0) {
          this.$message.success("清空购物车成功");
        }
        this.$store.dispatch("user/getCartNum")
        this.$router.go(0)
        this.initCartDataPage()
        this.pageLoadFlag = false;
      }).catch(error => {
        this.pageLoadFlag = false;
        console.log(error)
      })

    },

    // 初始化购物车页面信息
    async initCartDataPage() {
      this.pageLoadFlag = true
      this.filterFrom = { ...this.filterFrom };
      const res = await cartParam(this.filterFrom)
      // 获取到当前用户存在的组合类型信息
      this.orgAndGroupKeys = []
      this.orgAndGroupArray = []
      if (res.data.list && res.data.list.length > 0) {
        this.orgAndGroupArray = res.data.list
      }
      if (this.orgAndGroupArray.length < 1) {
        this.pageLoadFlag = false
        this.showImg = true
      }
      this.cartDataArray = []
      if (this.orgAndGroupArray && this.orgAndGroupArray.length > 0) {
        this.orgAndGroupArray.forEach(item => {
          this.orgAndGroupKeys.push(item.code)
          this.queryCartDataList(item.code, item.name, item.type)
        })
      }

    },
    async queryCartDataList(code, name, type) {
      let supplierSTR = name.split('-');
      let supplierName = supplierSTR[0]
      let materialName = supplierSTR[1]
      let materialNameSj = null
      if (supplierSTR.length == 3) {
        materialNameSj = supplierSTR[2]
      }
      let supplierType = supplierSTR.length

      this.filterFrom = { ...this.filterFrom };
      const res = await showCartList({ orgAndGroup: code, purchaseType: this.filterFrom.purchaseType })
      const { cartDTOs, policyMap } = res.data
      // 优惠政策Key数组
      let policyKeys = []
      if (policyMap) {
        policyKeys = Object.keys(policyMap)
      }
      if (cartDTOs && cartDTOs.length > 0) {
        let cartInfo = {
          // 供应商
          supplier: name,
          type: type,
          supplierType: supplierType,
          supplierName: supplierName,
          materialName: materialName,
          materialNameSj: materialNameSj,
          code: code,
          cartDTOArray: [],
          isSelectAll: false,
          // 商品总量
          commodityNum: 0,
          // 体积合计 
          volumeTotal: 0,
          // 合计金额
          amountTotal: 0,
          canCherk: false,
        }

        let cartDTOArray = []
        let codeArr = []
        cartDTOs.forEach((item) => {
          let cartData = {
            ...item,
            canQuantity: 1000000000,
            pliceNameFull: '',
            supplierType: supplierType,
            policies: [],
            // priceInfo: {},
            pricestand: '',
            oldPrice: '',
            discut: '',
            CompensationType: '',
            PricingGroup: '',
            PricingGroupCode: '',
            stockInfo: {},
            stockInfoDMS: {},
            checkSelectFlag: false,
            openPoliciesFlag: false,
            policyId1: null,
          }
          // console.log('item',cartInfo);
          // if (item.isSell == 'Y') {
          //   cartInfo.canCherk = false
          // } else {
          //   cartInfo.canCherk = true
          // }
         
          // 根据产品列表获取价格 
          if (cartData.isFenXiao == 'Y') {
            getChannelPrice({ productId: cartData.productId, orgId: cartData.orgId, orgCode: cartData.orgCode }).then(res => {
              cartData.pricestand = res.data.list[0].standardPrice.toFixed(2)
            }).catch(error => {
              console.log(error)
            })
            const { orgCode, agentCode, modelSap, b2bName } = cartData;
            try {
              ctsApi({
                serviceCode: "displayReturnPolicy",
                bigContractNo: "",
                companyCode: orgCode,
                contractNo: "",
                distributorsCisCode: this.$store.state.user.userInfo.account.account,
                dlMdmCode: agentCode,
                model: modelSap || b2bName,
                pricingGroup: "",
                tableRow: "",
                isActivityArea: false
              }).then(res=>{
                if(res && res.data && res.data.data && res.data.data.model){
                  cartData.isShowRebate = true;
                  cartData.returnAmount = res.data.data.returnAmount;
                  cartData.upperLimit = res.data.data.upperLimit;
                }
              })
            } catch (error) {
            }
          } else if (cartData.isFenXiao == 'N'){
              getPrices({ code: cartData.productId, orgId: cartData.orgId, orgCode: cartData.orgCode }).then(res => {
              cartData.discut = res.data[0].fixedDiscount,
                cartData.CompensationType = res.data[0].makeUpType,
                cartData.oldPrice = (res.data[0].price).toFixed(2),
                cartData.PricingGroup = res.data[0].pricingGroupName,
                cartData.PricingGroupCode = res.data[0].pricingGroupCode,
                cartData.pricestand = res.data[0].retailPrice.toFixed(2)
                const { orgCode, modelSap, b2bName, PricingGroupCode } = cartData;
                try {
                  ctsApi({
                    serviceCode: "agentDirectDealerReturnPolicy",
                    bigContractNo: "",
                    companyCode: orgCode,
                    contractNo: "",
                    distributorsCisCode: "",
                    dlMdmCode: this.$store.state.user.userInfo.customer.customerCode,
                    model: modelSap || b2bName,
                    pricingGroup: PricingGroupCode,
                    tableRow: "",
                    isActivityArea: false
                  }).then(res=>{
                    if(res && res.data && res.data.data && res.data.data.model){
                      cartData.isShowRebate = true;
                      cartData.returnAmount = res.data.data.returnAmount;
                      cartData.upperLimit = res.data.data.upperLimit;
                    }
                  })
                } catch (error) {
                }
            }).catch(error => {
              console.log(error)
            })
          }
          // 查询库存数据
          if (cartData.isFenXiao == 'Y') {
            // 如果是渠道采购  调dms接口 如果是海信采购 调cis
             getChannelStock({ productCodes: [(cartData.productId).toString()], supplierCode: cartData.agentCisCode }).then(res => {
              // console.log(JSON.parse(res.data).data)
              cartData.stockInfo = JSON.parse(res.data).data.length>0 ? JSON.parse(res.data).data[0] : [];
              cartData.stockInfo.invQty =  JSON.parse(res.data).data.length>0 ? JSON.parse(res.data).data[0].invQty : '';
            })
          } else if (cartData.isFenXiao == 'N'){
              getStocks({ code: cartData.productId, orgId: cartData.orgId, orgCode: cartData.orgCode }).then(res => {
              cartData.stockInfo = res.data[0]
            }).catch(error => {
              console.log(error)
            })
            getChannelStockLIST({ productCodes: [(cartData.productId).toString()], supplierCode: '' }).then(async(stocksRes) => {
              // 判断后端返回code 是否成功并且data数组不为空 否则直接赋值空数组
              let res = JSON.parse(stocksRes.data).code == 0 && JSON.parse(stocksRes.data).data.length > 0 ? JSON.parse(stocksRes.data).data : [];
              cartData.stockInfoDMS = res;
              cartData['stockInfoDMS']['invQty'] =   res && res.length > 0 ? res[0].invQty : 0;
              cartData['stockInfoDMS']['gicInvQty'] =   res && res.length > 0 ? res[0].gicInvQty : 0;
              // 我的库存
              if(res && res.length > 0 && Number(res[0].gicInvQty) >= 0 && Number(res[0].invQty) >= 0) {
                cartData['stockInfoDMS']['dmsKC'] = res && res.length > 0 ? Number(res[0].gicInvQty)+ Number(res[0].invQty) : 0
              } else {
                cartData['stockInfoDMS']['dmsKC'] = 0
              }
              this.$forceUpdate()
            })
          }
          

          if (policyKeys && policyKeys.length > 0 && policyKeys.indexOf(item.productId.toString()) !== -1) {
            cartData.policies = policyMap[item.productId.toString()]
            if (cartData.policyId1 == null) {
              cartData.policyId1 = cartData.policyId
            }
            cartData.policies.forEach(polItem => {
              polItem.ischerk = false
              if (cartData.policyId1 == polItem.id) {
                if (polItem.canQuantity > cartData.quantity) {
                  if (polItem.versionCode) {
                    cartData.pliceNameFull = polItem.policyName + '-' + polItem.versionCode + "\xa0\xa0" + '开票价:' + polItem.price + '元' + '\xa0\xa0' + '可购数量:' + polItem.canQuantity
                  } else {
                    cartData.pliceNameFull = polItem.policyName + '  开票价:' + polItem.price + '元' + '\xa0\xa0' + '可购数量:' + polItem.canQuantity
                  }
                  // cartData.pliceNameFull = polItem.policyName +'-'+polItem.versionCode
                  polItem.ischerk = true
                  cartData.canQuantity = polItem.canQuantity
                  let params = {
                    versionId: cartData.policyId1,
                    orgCode: item.orgcode,
                    orgId: item.orgId,
                    productId: cartData.productId
                  }
                  getCust(params).then(res => {
                    cartData.PricingGroup = res.data.pricingGroupName
                    cartData.PricingGroupCode = res.data.pricingGroupCode
                    cartData.CompensationType = res.data.makeUpType
                    cartData.discut = res.data.fixedDiscount
                    cartData.pricestand =
                      ((polItem.price * (100 - (res.data.fixedDiscount))) / 100).toFixed(2)

                  }).catch(error => {
                    console.log(error)
                  })
                }


              }
            })
          }
          cartDTOArray.push(cartData)
        })
        
        cartInfo['cartDTOArray'] = cartDTOArray

        this.cartDataArray.push(cartInfo)
        if (this.cartDataArray.length < 1) {
          this.showImg = true
        } else {
          this.showImg = false
        }
 
        this.cartDataArray.forEach(item=>{
          item.cherkNum = [] 
          item.cartDTOArray.forEach(goodItem=>{
            if(goodItem.isSell === 'N'){
              item.cherkNum.push(goodItem)
            }
          })
          if(item.cartDTOArray.length == item.cherkNum.length) {
            item.canCherk = true
          } else {
            item.canCherk = false
          }
        
        })
       
    }

      this.pageLoadFlag = false;

      // 使购物车顺序和接口顺序一致
      let arr1 = []
      this.orgAndGroupArray.forEach(item => {
        arr1.push(item.code)
      })
      this.cartDataArray.sort((a, b) => {
        const prev = arr1.indexOf(a.code)
        const next = arr1.indexOf(b.code)
        return prev - next
      });
    },
    // 筛选数据重构
    getNewObj(obj) {
      let newArr = [],
        data = {
          id: "",
          name: "全部",
          isActive: true
        };
      for (let key in obj) {
        newArr.push({
          id: key,
          name: obj[key],
          isActive: false
        });
      }
      newArr.unshift(data);
      return newArr;
    },

    //获取历史记录列表
    getProductSearch() {
      this.pageLoadFlag = true;
      let params = this.param
      productSearch(params)
        .then(res => {
          this.dataList = res.data.list;
          this.total = res.data.totalRows;
          this.pageLoadFlag = false;

          let param = {
            code: [],
            orgId: [],
            orgCode: []
          };
          for (const key in this.dataList) {
            param.code.push(this.dataList[key].productCode);
            param.orgId.push(this.dataList[key].orgId);
            param.orgCode.push(this.dataList[key].orgCode);
          }
          // 根据产品列表获取价格
          getPrices(param)
            .then(pricesRes => {
              // 源数据赋值库存
              if (pricesRes.statusText == "OK") {
                for (const key in res.data.list) {
                  // 标准价格
                  this.$set(
                    this.dataList[key],
                    "price",
                    pricesRes.data[key].price
                  );
                  // 零售价格
                  this.$set(
                    this.dataList[key],
                    "retailPrice",
                    pricesRes.data[key].retailPrice
                  );


                }
              }
            })
            .catch(error => {
              console.log(error);
            });
          // 根据产品列表获取库存
          getStocks(param)
            .then(stocksRes => {
              // 源数据赋值库存
              if (stocksRes.statusText == "OK") {
                for (const key in res.data.list) {

                  this.$set(
                    this.dataList[key],
                    "inventory",
                    stocksRes.data[key].inventory
                  );
                }
              }
            })
            .catch(error => {
              console.log(error);
            });
        })
        .catch(error => {
          console.log(error);
        });
    }

  }
}


